import React, { useState, useEffect, useCallback } from 'react'

import { CompactTable } from '@table-library/react-table-library/compact'
import { useTheme } from '@table-library/react-table-library/theme'
// import { getTheme } from '@table-library/react-table-library/baseline'
import { useSort } from '@table-library/react-table-library/sort'

import {
  DEFAULT_OPTIONS,
  getTheme,
} from '@table-library/react-table-library/chakra-ui'

import './UserTable.css'

import {
  SignUpPageWrapper,
  SignUpFormWrapper,
  Heading,
} from '../Users/UsersElements'

import Navbar from '../../components/Headers/Navbar'
import MobileNavMenu from '../../components/Headers/MobileNavMenu'
import Footer from '../../components/Footer/Footer'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { FaCheck, FaCross, FaEdit, FaTimes, FaTrash } from 'react-icons/fa'
import { NavItemSection } from '../Downloads/NavComponents/FooterDownloadsStyles'

const { REACT_APP_MAIN_API_URL } = process.env

const UserManagement = ({ setToken }) => {
  const [isOpen, setIsOpen] = useState(false)

  const [data, setData] = useState({ nodes: [] })

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const fetchData = useCallback(() => {
    axios
      .get(REACT_APP_MAIN_API_URL + '/users/get-all')
      .then(function (response) {
        if (response.data == undefined || response.data == null) {
          return
        }
        setData({ nodes: response.data })
      })
      .catch(function (error) {
        toast.error('There was an issue retrieving users: ' + error, {
          position: 'top-right',
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      })
  }, [])

  useEffect(
    (isOpen) => {
      setIsOpen(isOpen)

      fetchData()

      return () => {
        setIsOpen(false)
      }
    },
    [fetchData]
  )

  let patchUser = useCallback((node, value, property) => {
    axios
      .patch(REACT_APP_MAIN_API_URL + '/users/' + node.id, {
        [property]: value,
      })
      .then(function (response) {
        toast.success(
          'Successfully updated ' +
            property +
            ' for ' +
            node.name +
            ' to ' +
            value,
          {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: 'UserUpdateSuccess' + node.id + value,
          }
        )
      })
  }, [])

  const handleUpdate = (value, id, property) => {
    setData((state) => ({
      ...state,
      nodes: state.nodes.map((node) => {
        if (node.id === id) {
          patchUser(node, value, property)
          return { ...node, [property]: value }
        } else {
          console.log('Non-Matching node')
          return node
        }
      }),
    }))
  }

  const handleDelete = (id) => {
    axios.delete(REACT_APP_MAIN_API_URL + '/users/' + id)
      .then(function (response) {
        toast.success(
          'Successfully Deleted User ',
          {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: 'Successfully Deleted' + id,
          }
        )
      })
  }

  const theme = getTheme(DEFAULT_OPTIONS)

  const sort = useSort(
    data,
    {
      state: {
        sortKey: 'NAME',
        reverse: false,
      },
      onChange: onSortChange,
    },
    {
      sortFns: {
        NAME: (array) => array.sort((a, b) => a.name.localeCompare(b.name)),
        EMAIL: (array) => array.sort((a, b) => a.email.localeCompare(b.email)),
      },
    }
  )

  function onSortChange(action, state) {
    console.log(action, state)
  }

  const COLUMNS = [
    {
      label: 'Name',
      renderCell: (item) => item.name,
      sort: { sortKey: 'NAME' },
    },
    {
      label: 'Email',
      renderCell: (item) => item.email,
      sort: { sortKey: 'EMAIL' },
    },
    {
      label: 'Verified Email',
      renderCell: (item) =>
        item.is_verified ? (
          <FaCheck style={{ color: 'green' }}></FaCheck>
        ) : (
          <FaTimes style={{ color: 'red' }}></FaTimes>
        ),
    },
    {
      label: 'Beta Auth (licensed)',
      renderCell: (item) => (
        <input
          type="checkbox"
          checked={item.is_beta_authorized}
          onChange={(event) =>
            handleUpdate(event.target.checked, item.id, 'is_beta_authorized')
          }
        />
      ),
    },
    {
      label: 'Super User',
      renderCell: (item) => (item.is_superuser ? <FaCheck></FaCheck> : ''),
    },
    {
      label: 'Active',
      renderCell: (item) => (
        <input
          type="checkbox"
          checked={item.is_active}
          onChange={(event) =>
            handleUpdate(event.target.checked, item.id, 'is_active')
          }
        />
      ),
    },
    {
      label: 'Delete',
      renderCell: (item) => (
        <button
          type="button"
          onClick={(event) =>
            handleDelete(item.id, 'is_active')
          }
        />
      ),
    },
    // {
    //   label: 'Actions',
    //   renderCell: (item) => (
    //     <div>
    //       <FaEdit
    //         style={{ fontSize: 22 }}
    //         onClick={() => handleEdit(item)}
    //       ></FaEdit>
    //       <FaTrash
    //         style={{ fontSize: 18, marginLeft: 15, marginBottom: 2 }}
    //         onClick={() => handleDelete(item)}
    //       ></FaTrash>
    //     </div>
    //   ),
    // },
  ]

  return (
    <>
      <Navbar toggle={toggle} />
      <MobileNavMenu isOpen={isOpen} toggle={toggle} />
      <SignUpPageWrapper>
        <SignUpFormWrapper>
          <Heading>Experimenter Users</Heading>
          <div className="userTable">
            <CompactTable
              style={{ 'background-color': 'grey' }}
              columns={COLUMNS}
              data={data}
              theme={theme}
              sort={sort}
            />
          </div>
        </SignUpFormWrapper>
        <Footer />
      </SignUpPageWrapper>
    </>
  )
}

export default UserManagement
